import axios from "axios";
const actions = {
  getSettingsList(context) {
    context.commit("SET_SETTINGS_LIST");
    let getUrl = context.getters.backendServer + "settings";
    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_SETTINGS_LIST", response.data);
      })
      .catch(function(e) {
        context.commit("SET_SETTINGS_LIST", e);
        context.dispatch("triggerError", e);
      });
  },
  getSettingParticipationStatus(context) {
    let getUrl = context.getters.backendServer + "settings";
    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit(
          "SET_SETTINGS_PARTICIPATIONS_STATUS",
          response.data.AllowShareHolding.value
        );
        context.commit(
          "SET_SETTINGS_PARTICIPATIONS",
          response.data.AllowShareHolding
        );
      })
      .catch(function(e) {
        context.commit("SET_SETTINGS_PARTICIPATIONS_STATUS", false);
      });
  },
  setSettingParticipationStatus(context, value) {
    let postUrl = context.getters.backendServer + "settings/" + value.setting.id;

    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    let putValue = {
      name: "AllowShareHolding",
      value: value.value
    };
    axios
      .put(postUrl, putValue, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Status erfolgreich verändert");
        context.dispatch("getSettingParticipationStatus");
      })
      .catch(function(e) {});
  },
  getSettingMenuPlanStatus(context) {
    let getUrl = context.getters.backendServer + "settings";
    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit(
          "SET_SETTINGS_MENU_PLAN_STATUS",
          response.data.AllowMenuPlan.value
        );
        context.commit(
          "SET_SETTINGS_MENU_PLAN",
          response.data.AllowMenuPlan
        );
      })
      .catch(function(e) {
        context.commit("SET_SETTINGS_MENU_PLAN_STATUS", false);
      });
  },
  setSettingMenuPlanStatus(context, value) {
    let postUrl = context.getters.backendServer + "settings/" + value.setting.id;

    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    let putValue = {
      name: "AllowMenuPlan",
      value: value.value
    };
    axios
      .put(postUrl, putValue, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Status erfolgreich verändert");
        context.dispatch("getSettingParticipationStatus");
      })
      .catch(function(e) {});
  },

  setSettingParticipationStatus(context, value) {
    let postUrl = context.getters.backendServer + "settings/" + value.setting.id;

    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    let putValue = {
      name: "AllowShareHolding",
      value: value.value
    };
    axios
      .put(postUrl, putValue, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Status erfolgreich verändert");
        context.dispatch("getSettingParticipationStatus");
      })
      .catch(function(e) {});
  },
  getSettingPreOrderStatus(context) {
    let getUrl = context.getters.backendServer + "settings";
    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit(
          "SET_SETTINGS_PRE_ORDER_STATUS",
          response.data.AllowPreOrder.value
        );
        context.commit(
          "SET_SETTINGS_PRE_ORDER",
          response.data.AllowPreOrder
        );
      })
      .catch(function(e) {
        context.commit("SET_SETTINGS_PRE_ORDER_STATUS", false);
      });
  },
  setSettingPreOrderStatus(context, value) {
    let postUrl = context.getters.backendServer + "settings/" + value.setting.id;

    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    let putValue = {
      name: "AllowPreOrder",
      value: value.value
    };
    axios
      .put(postUrl, putValue, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Status erfolgreich verändert");
        context.dispatch("getSettingParticipationStatus");
      })
      .catch(function(e) {});
  }
};

export default actions;
