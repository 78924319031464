const axios = require('axios');


const mutations = {
    THROW_ERROR(state, payload) {
        state.error.visible = true;
        state.error.message = payload.err;
        state.errors.push(state.error);
    },
    CREATE_SNACKBAR(state, payload) {
        state.snackbar.visible = true;
        state.snackbar.message = payload;
    },
}

export default mutations;