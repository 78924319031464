import axios from "axios";
const actions = {
  getMenuPlanOrderList(context, id) {
    let getUrl =
      context.getters.backendServer +
      "order/allListMenuPlan?user=" +
      localStorage.getItem("userId") +
      "&menuPlan=" +
      id;
    let config = {
      headers: {
        auth: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getPreOrderOrderList(context, id) {
    let getUrl =
      context.getters.backendServer +
      "order/allListPreOrder?user=" +
      localStorage.getItem("userId") +
      "&preOrder=" +
      id;
    let config = {
      headers: {
        auth: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
