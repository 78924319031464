<template>
<div>
    <div class="text-center">
        <v-snackbar v-model="snackbar.visible" :timeout="2000" left color="success">
            {{ snackbar.text }}
            <v-btn color="primary" text @click="snackbar.visible = false">Ok!</v-btn>
        </v-snackbar>
    </div>
    <v-card class="ma-4">
        <v-data-table :headers="headers" :items="participationsListGetter" :search="search" sort-by="calories" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Beteiligungen</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" append-icon="search" label="Suche..." single-line hide-details dense></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-switch hide-details label="Bereich Beteiligungen aktivieren/ deaktivieren" v-model="participationStatus"></v-switch>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" @click="deleteItem(item)">delete</v-icon>
                    </template>
                    <span>Eintrag löschen</span>
                </v-tooltip>
            </template>
            <template v-slot:no-data>
                <p>keine Daten vorhanden</p>
            </template>
        </v-data-table>
    </v-card>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
export default {
    data: () => ({
        search: "",
        snackbar: {
            visible: false,
            text: "Snackbar ..."
        },
        headers: [{
                text: "Abgeschickt am",
                align: "left",
                sortable: false,
                value: "formattedCreatedAt"
            },
            {
                text: "Vorname",
                value: "prename"
            },
            {
                text: "Nachname",
                value: "surname"
            },
            {
                text: "Adresse",
                value: "address",
                sortable: false
            },
            {
                text: "Telefon",
                value: "phone",
                sortable: false
            },
            {
                text: "E-Mail",
                value: "email",
                sortable: false
            },
            {
                text: "gewünschte Beteiligung in €",
                value: "shareInEuro"
            },
            {
                text: "",
                value: "action"
            }
        ]
    }),
    computed: {
        ...mapGetters(["participationsListGetter", "settingParticipationStatus", "settingParticipation"]),
        participationStatus: {
            get: function () {
                return this.settingParticipationStatus;
            },
            set: function (newValue) {
                this.$store
                    .dispatch("setSettingParticipationStatus", {
                        value: newValue,
                        setting: this.settingParticipation
                    })
                    .then(result => {});
            }
        }
    },
    mounted() {
        this.$store.dispatch("getParticipationsList").then(result => {});
        this.$store.dispatch("getSettingParticipationStatus").then(result => {});
    },
    methods: {
        deleteItem(item) {
            this.$store.dispatch("deleteParticipations", item).then(result => {});
        }
    }
};
</script>
