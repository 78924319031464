import { StoreUtil } from "../utils";

const mutations = {

    SET_SETTINGS_LIST(state, payload) {
        state.settingsList = StoreUtil.updateState(state.settingsList, payload);
    },
    SET_SETTINGS_PARTICIPATIONS_STATUS(state, payload) {
        state.settingParticipationStatus = payload;
    },
    SET_SETTINGS_PARTICIPATIONS(state, payload) {
        state.settingParticipation = payload;
    },
    SET_SETTINGS_MENU_PLAN_STATUS(state, payload) {
        state.settingMenuPlanStatus = payload;
    },
    SET_SETTINGS_MENU_PLAN(state, payload) {
        state.settingMenuPlan = payload;
    },
    SET_SETTINGS_PRE_ORDER_STATUS(state, payload) {
        state.settingPreOrderStatus = payload;
    },
    SET_SETTINGS_PRE_ORDER(state, payload) {
        state.settingPreOrder = payload;
    }
}

export default mutations;