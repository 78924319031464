import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { StoreUtil } from "../utils";

const state = {
  messangesList: StoreUtil.state()
};

const messangesModule = {
  state,
  mutations,
  actions,
  getters
};

export default messangesModule;
