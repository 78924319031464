import router from "../../router.js";
const actions = {
  triggerError(context, error) {
    if (error == "Error: Request failed with status code 401") {
      context.dispatch("logout");
      router.push("login");
    } else {
      context.commit("THROW_ERROR", {
        err: error,
        con: context
      });
    }
  },
  triggerSnackbar(context, text) {
    context.commit("CREATE_SNACKBAR", text);
  },
  formatToDateTime(context, timeString) {
    return (
      timeString.substring(8, 10) +
      "." +
      timeString.substring(5, 7) +
      "." +
      timeString.substring(0, 4) +
      " " +
      timeString.substring(11, 16)
    );
  },
  formatToDate(context, timeString) {
    return (
      timeString.substring(8, 10) +
      "." +
      timeString.substring(5, 7) +
      "." +
      timeString.substring(0, 4)
    );
  }
};

export default actions;
