import { StoreUtil } from "../utils";

const mutations = {

    SET_PARTICIPATIONS_LIST(state, payload) {
        state.participationsList = StoreUtil.updateState(state.participationsList, payload);
    },
    SET_PARTICIPATIONS_DELETE(state, payload) {
        StoreUtil.updateState(state.participationsList, payload);
    }
}

export default mutations;