import { StoreUtil } from "../utils";

const mutations = {

    SET_PREORDER_LIST(state, payload) {
        state.preOrderList = StoreUtil.updateState(state.preOrderList, payload);
    },
    SET_PREORDER_DELETE(state, payload) {
        StoreUtil.updateState(state.preOrderList, payload);
    }
}

export default mutations;