<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader><h4>Stammdaten</h4></v-subheader>

      <v-list-item v-if="allEntries.basicInfo != null">
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      v-model="item.name"
                      label="Name des Dorf- oder Bürgerladens"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.location"
                      label="Ort"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.zip"
                      label="PLZ"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.contact.person"
                      label="Ansprechpartner / Geschäftsführung"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <br />
                <v-divider></v-divider>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.contact.telephone"
                      label="Telefon"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.contact.mail"
                      label="Email"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.contact.adress"
                      label="Adresse (Straße)"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <br />
                <v-divider></v-divider>
                <br />
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="item.descriptionShort"
                    label="Beschreibungstext kurz"
                    dense
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="item.descriptionLong"
                    label="Beschreibungstext lang"
                    dense
                  ></v-textarea>
                </v-col>
              </v-form>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getInformationentries()"
                  >Zurück</v-btn
                >
                <v-btn color="blue darken-1" text @click="setBasicInfo()"
                  >Speichern</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="allEntries.openingHours != null">
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
                <p>Öffnungszeiten</p>
                <v-switch
                  color="primary"
                  hide-details
                  label="Öffnungszeiten anzeigen/ ausblenden"
                  v-model="openingHours.visible"
                ></v-switch>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="openingHours.mon"
                      label="Montag"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="openingHours.tue"
                      label="Dienstag"
                      dense
                    ></v-text-field>
                  </v-col> </v-row
                ><v-row>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="openingHours.wed"
                      label="Mittwoch"
                      dense
                    ></v-text-field>
                  </v-col> </v-row
                ><v-row>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="openingHours.thu"
                      label="Donnerstag"
                      dense
                    ></v-text-field>
                  </v-col> </v-row
                ><v-row>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="openingHours.fri"
                      label="Freitag"
                      dense
                    ></v-text-field>
                  </v-col> </v-row
                ><v-row>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="openingHours.sat"
                      label="Samstag"
                      dense
                    ></v-text-field>
                  </v-col> </v-row
                ><v-row>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="openingHours.sun"
                      label="Sonntag"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="blue darken-1" text @click="getInformationentries()">Zurück</v-btn>
                <v-btn color="blue darken-1" text @click="setopeningHours()">Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {},
  data: () => ({
    valid: true,
    requieresRule: [v => !!v || "Dieses Feld wird benötigt"],
    allEntries: null,
    item: null,
    openingHours: null
  }),
  mounted() {
    this.getInformationentries();
  },
  computed: {
    ...mapGetters(["backendServer"])
  },
  methods: {
    getInformationentries() {
      let vm = this;
      let getUrl = this.backendServer + "informationEntry/my";

      let config = {
        headers: {
          auth: localStorage.getItem("token")
        }
      };

      axios
        .get(getUrl, config)
        .then(function(response) {
          vm.allEntries = response.data;
          vm.item = response.data.basicInfo.value;
          vm.openingHours = response.data.openingHours.value;
        })
        .catch(function(e) {
          vm.$store.dispatch("triggerSnackbar", e);
        });
    },
    setBasicInfo() {
      this.setInformationentries(this.allEntries.basicInfo.id, this.item);
    },
    setopeningHours() {
      this.setInformationentries(this.allEntries.openingHours.id, this.openingHours);
    },
    setInformationentries(id, entryValue) {
      let vm = this;
      let putUrl = this.backendServer + "informationEntry/" + id;

      let config = {
        headers: {
          auth: localStorage.getItem("token")
        }
      };

      let putValue = {
        value: entryValue
      };
      axios
        .put(putUrl, putValue, config)
        .then(function(response) {
          vm.$store.dispatch(
            "triggerSnackbar",
            "Daten erfolgreich gespeichert"
          );
        })
        .catch(function(e) {
          vm.$store.dispatch("triggerSnackbar", e);
        });
    }
  }
};
</script>
<style></style>
