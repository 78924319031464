<template>
  <v-app id="inspire">
    <v-content>
      <div class="text-center">
      <v-snackbar v-model="error.visible" :timeout="4000" left color="#ff5252">{{ error.message }}</v-snackbar>
      <v-snackbar v-model="snackbar.visible" :timeout="4000" left color="#ff5252" >{{ snackbar.message }}</v-snackbar>
      </div>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <v-container class="background fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="6" sm="6" md="3">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{ companyData.name }} Administration</v-toolbar-title>
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Login"
                    name="login"
                    prepend-icon="person"
                    type="text"
                    v-model="login.email"
                    dense
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    v-model="login.password"
                    dense
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn v-on:click="auth" color="primary">Anmelden</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <appFooter />
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import appFooter from "../components/styling/footer";
import { mapGetters } from "vuex";

import "vue-loading-overlay/dist/vue-loading.css";
export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: null,
    isLoading: false,
    fullPage: true,
    login: {
      email: "",
      password: ""
    }
  }),
  components: {
    Loading,
    appFooter
  },
  computed: {
    ...mapGetters(["loading", "loggedIn", "error", "snackbar", "companyData"])
  },
  methods: {
    auth() {
      let vm = this;
      this.$store
        .dispatch("login", {
          email: this.login.email,
          password: this.login.password
        })
        .then(() =>
          this.loggedIn === true
            ? vm.$router.push("dashboard")
            : vm.$router.push("login")
        );
    }
  }
};
</script>
<style>
.background {
  background-color: #f3f3fa;
}
</style>
