<template>
  <div>
    <v-navigation-drawer v-model="drawer" app permanent expand-on-hover>
      <v-list dense>
        <v-row align="center">
          <v-col cols="12" class="text-center">
            <v-list-item class="px-2">
              <v-list-item-avatar>
                <v-icon color="primary">shopping_basket</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-col>
        </v-row>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="v-list-item-title-color">{{
                    item.text
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              @click="navigateTo(child.route)"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="v-list-item-title-color">{{
                  child.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" @click="navigateTo(item.route)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <div class="text-center">
      <v-snackbar
        v-model="error.visible"
        :timeout="4000"
        left
        color="#ff5252"
        >{{ error.message }}</v-snackbar
      >
      <v-snackbar
        v-model="snackbar.visible"
        :timeout="4000"
        left
        color="secondary"
        >{{ snackbar.message }}</v-snackbar
      >
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["drawer"],
  data: () => ({
    dialog: false,
    items: [
      { icon: "send", text: "Beiträge", route: "messanges" },
      { icon: "account_balance", text: "Beteiligungen", route: "participations" },
      { icon: "restaurant", text: "Menüplan", route: "menuPlan" },
      { icon: "storefront", text: "Vorbestellungen", route: "preOrder" },
      { icon: "info", text: "Infos / Stammdaten", route: "settings" },
    ],
    allItems: [
      { icon: "send", text: "Beiträge", route: "messanges" },
      { icon: "info", text: "Informationen", route: "" },
      { icon: "group", text: "Abbonenten", route: "" },
      { icon: "settings", text: "Settings" }
    ]
  }),
  computed: {
    ...mapGetters(["error", "snackbar"])
  },
  methods: {
    navigateTo(destination) {
      this.$router.push(destination);
    }
  }
};
</script>
<style scoped>
.divider {
  border-top: 1px solid#e8e8e8;
}

.v-list-item-title-color {
  color: #717171;
}
</style>
