import Vue from "vue";
import Router from "vue-router";
import generalLayout from "./components/generalLayout.vue";
import Login from "./views/Login.vue";
import Messanges from "./views/messanges.vue";
import Participations from "./views/participationTable.vue";
import menuPlan from "./views/menuPlanTable.vue"
import preOrder from "./views/preOrderTable.vue"
import Settings from "./views/informationSettings.vue"
const backendServer = require("./store/config/index").default.state
  .backendServer;
const axios = require("axios");

Vue.use(Router);
const router = new Router({
  routes: [
    { path: "*", redirect: "/messanges" },
    {
      path: "/",
      component: generalLayout,
      name: "generalLayout",
      redirect: "/messanges",
      meta: { requiresAuth: true, requireAdmin: true },
      children: [
        {
          path: "/messanges",
          name: "Beiträge",
          component: Messanges,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/participations",
          name: "Beteiligungen",
          component: Participations,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/menuPlan",
          name: "menuPlan",
          component: menuPlan,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/preOrder",
          name: "preorder",
          component: preOrder,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/settings",
          name: "Einstellungen",
          component: Settings,
          meta: { requiresAuth: true, requireAdmin: true }
        }
      ]
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!window.localStorage.getItem("token")) {
      next("/login");
    } else {
      //CHECK TOKEN
      let getUrl = backendServer + "hasValidToken";
      let config = {
        headers: {
          auth: localStorage.getItem("token")
        }
      };
      axios
        .get(getUrl, config)
        .then(function(response) {
          next();
        })
        .catch(e => {
          localStorage.clear();
          this.$router.push("login");
        });
    }
  } else {
    next();
  }
});

export default router;
