import { StoreUtil } from "../utils";

const mutations = {

    SET_MENUPLAN_LIST(state, payload) {
        state.menuPlanList = StoreUtil.updateState(state.menuPlanList, payload);
    },
    SET_MENUPLAN_DELETE(state, payload) {
        StoreUtil.updateState(state.menuPlanList, payload);
    }
}

export default mutations;