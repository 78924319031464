import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { StoreUtil } from "../utils";

const state = {
  settingsList: StoreUtil.state(),
  settingParticipationStatus: null,
  settingParticipation: null,
  settingMenuPlanStatus: null,
  settingMenuPlan: null,
  settingPreOrderStatus: null,
  settingPreOrder: null
};

const settingsModule = {
  state,
  mutations,
  actions,
  getters
};

export default settingsModule;
