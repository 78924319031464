import axios from "axios";

const actions = {
  login({ commit, getters }, loginData) {
    commit("LOGIN_PENDING");
    return axios
      .post(getters.backendServer + "login", {
        email: loginData.email,
        password: loginData.password
      })
      .then(response => {
        /*if (response.data.role === "Worker") {
          commit("CREATE_SNACKBAR", "Berechtigungsfehler - worker");
          commit("LOGIN_FAILED");
        } else {
          localStorage.setItem("token", response.data.token);
          commit("SET_TOKEN", response.data.token);
          commit("SET_STATIC_TOKEN", response.data.static_token);
          commit("LOGIN_SUCCESS");
        }*/
        localStorage.setItem("userId", response.data.user.id);
        localStorage.setItem("token", response.data.token);
          commit("SET_TOKEN", response.data.token);
          commit("LOGIN_SUCCESS");
      })
      .catch(err => {
        commit("CREATE_SNACKBAR", "Passwort oder Email nicht korrekt.");
        commit("LOGIN_FAILED");
      });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      localStorage.removeItem("token");
      commit("SET_TOKEN", null);
      resolve();
    });
  }
};

export default actions;
