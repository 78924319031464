import axios from "axios";
const actions = {
  getMessangesList(context) {
    context.commit("SET_MESSANGES_LIST");
    let getUrl = context.getters.backendServer + "newsFeedEntry/my/feed";
    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_MESSANGES_LIST", response.data);
      })
      .catch(function(e) {
        context.commit("SET_MESSANGES_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  addMessanges(context, value) {
    context.commit("SET_MESSANGES_ADD");
    let postUrl = context.getters.backendServer + "newsFeedEntry";
    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_MESSANGES_ADD", response);
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich hinzugefügt");
        context.dispatch("getMessangesList");
        return true;
      })
      .catch(function(e) {
        context.commit("SET_MESSANGES_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  updateMessanges(context, value) {
    context.commit("SET_MESSANGES_UPDATE");

    let postUrl = context.getters.backendServer + "newsFeedEntry/" + value._id;

    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_MESSANGES_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Beitrag erfolgreich verändert"
        );
        context.dispatch("getMessangesList");
      })
      .catch(function(e) {
        context.commit("SET_MESSANGES_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteMessanges(context, value) {
    context.commit("SET_MESSANGES_DELETE");
    let deleteUrl = context.getters.backendServer + "newsFeedEntry/" + value.id;

    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .delete(deleteUrl, config)
      .then(function() {
        context.commit("SET_MESSANGES_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich gelöscht");
        context.dispatch("getMessangesList");
      })
      .catch(function(e) {
        context.commit("SET_MESSANGES_DELETE", e);
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
