<template>
  <div>
    <div class="text-center">
      <v-snackbar
        v-model="snackbar.visible"
        :timeout="2000"
        left
        color="success"
      >
        {{ snackbar.text }}
        <v-btn color="primary" text @click="snackbar.visible = false"
          >Ok!</v-btn
        >
      </v-snackbar>
    </div>
    <v-card class="ma-4">
      <v-data-table
        :headers="headers"
        :items="menuPlanListGetter"
        :search="search"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        sort-by="calories"
        class="elevation-1"
        @click:row="clickedRow"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Menüplan</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Suche..."
              single-line
              hide-details
              dense
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-switch
              hide-details
              label="Bereich Menüplan aktivieren/ deaktivieren"
              v-model="menuPlanStatus"
            ></v-switch>
            <div class="flex-grow-1"></div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon>
                  <v-icon v-on="on" @click="addMenuPlan()">add</v-icon>
                </v-btn>
              </template>
              <span>Menüplan erstellen</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="ma-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Telefon
                    </th>
                    <th class="text-left">
                      Email
                    </th>
                    <th class="text-left">
                      Bestellte Gerichte
                    </th>
                    <th class="text-left">
                      Anmerkungen
                    </th>
                    <th class="text-left">
                      Bestellen aufgegeben am
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in item.orders" :key="order.id">
                    <td>{{ order.prename }} {{ order.surname }}</td>
                    <td>{{ order.phone }}</td>
                    <td>{{ order.email }}</td>
                    <td>
                      <div v-for="menuItem in order.orderItems" :key="menuItem">
                        <b
                          >1x {{ menuItem.title }} /
                          {{ menuItem.price }}€</b
                        ><br />
                      </div>
                    </td>
                    <td>{{ order.description }}</td>
                    <td>{{ order.formattedCreatedAt }}h</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="deleteItem(item)" class="mr-2"
                >delete</v-icon
              >
            </template>
            <span>Eintrag löschen</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="editItem(item)" class="mr-2"
                >edit</v-icon
              >
            </template>
            <span>Eintrag bearbeiten</span>
          </v-tooltip>
        </template>
        <template v-slot:item.formattedVisible="{ item }">
          <div v-if="item.active">
            <v-icon class="mr-2" small color="success">visibility</v-icon>
            sichtbar
          </div>
          <div v-else>
            <v-icon class="mr-2" small>visibility</v-icon> verborgen
          </div>
        </template>
        <template v-slot:item.formattedMenuPlanDays="{ item }">
          {{ menuPlanItemsDates(item.menuPlanItems) }}
        </template>
        <template v-slot:item.formattedOrders="{ item }">
          {{ item.orders.length }}
        </template>
        <template v-slot:no-data>
          <p>keine Daten vorhanden</p>
        </template>
      </v-data-table>
    </v-card>
    <menuPlanForm
      ref="menuPlanForm"
      :data="menuPlanForm"
      @reloadData="reloadDataFunc"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import menuPlanForm from "../components/menuPlanForm.vue";
export default {
  components: { menuPlanForm },
  data: () => ({
    search: "",
    expanded: [],
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    headers: [
      {
        text: "Bezeichnung",
        align: "left",
        sortable: false,
        value: "title",
      },
      {
        text: "Menüplan sichtbar",
        value: "formattedVisible",
      },
      {
        text: "Menüplan Tage",
        value: "formattedMenuPlanDays",
      },
      {
        text: "Erstellt am",
        value: "formattedCreatedAt",
      },
      {
        text: "Bestellungen",
        value: "formattedOrders",
      },
      {
        text: "",
        value: "action",
        align: "right",
      },
    ],
    menuPlanForm: {
      open: false,
      title: "",
      item: {},
    },
  }),
  computed: {
    ...mapGetters([
      "menuPlanListGetter",
      "settingMenuPlanStatus",
      "settingMenuPlan",
    ]),
    menuPlanStatus: {
      get: function() {
        return this.settingMenuPlanStatus;
      },
      set: function(newValue) {
        this.$store
          .dispatch("setSettingMenuPlanStatus", {
            value: newValue,
            setting: this.settingMenuPlan,
          })
          .then((result) => {});
      },
    },
  },
  mounted() {
    this.$store.dispatch("getMenuPlanList");
    this.$store.dispatch("getSettingMenuPlanStatus");
    this.$store.dispatch("getSettingsList");
  },
  methods: {
    clickedRow(value) {
      let test = [];
      test.push(value);
      if (test[0] === this.expanded[0]) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    deleteItem(item) {
      this.$store.dispatch("deleteMenuPlan", item);
    },
    editItem(item) {
      /*his.$store.dispatch("updateMenuPlan", item).then(() => {
        //reload menu plan data
        this.$store.dispatch("getMenuPlanList");
      });*/
      this.menuPlanForm.open = true;
      this.menuPlanForm.title = "Menüplan bearbeiten";
      this.menuPlanForm.method = "edit";
      this.$refs.menuPlanForm.triggerEdit(Object.assign({}, item));
    },
    addMenuPlan() {
      this.menuPlanForm.open = true;
      this.menuPlanForm.title = "Menüplan erstellen";
      this.menuPlanForm.method = "new";
    },
    menuPlanItemsDates(val) {
      let menuDatesString = "";
      val.forEach((item) => {
        menuDatesString =
          menuDatesString + item.formattedMenuDate.slice(0, -6) + ", ";
      });
      return menuDatesString;
    },
    reloadDataFunc() {
      //reload data
      this.$store.dispatch("getMenuPlanList");
    },
  },
};
</script>
<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
