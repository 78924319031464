import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { StoreUtil } from "../utils";

const state = {
  menuPlanList: StoreUtil.state()
};

const menuPlanModule = {
  state,
  mutations,
  actions,
  getters
};

export default menuPlanModule;
