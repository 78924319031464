const getters = {
  settingsListGetter: state => state.settingsList.data,
  settingsStatusPending: state => state.settingsList.status.pending,
  settingParticipationStatus: state => state.settingParticipationStatus,
  settingParticipation: state => state.settingParticipation,
  settingMenuPlanStatus: state => state.settingMenuPlanStatus,
  settingMenuPlan: state => state.settingMenuPlan,
  settingPreOrderStatus: state => state.settingPreOrderStatus,
  settingPreOrder: state => state.settingPreOrder
};

export default getters;
