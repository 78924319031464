import Vue from "vue";
import Vuex from "vuex";
import config from "./config/index.js";
import login from "./login/index.js";
import messanges from "./messanges/index.js";
import participations from "./participations/index.js"
import settings from "./settings/index.js"
import menuPlan from "./menuPlan/index.js"
import preOrder from "./preOrder/index.js"
import orders from "./orders/index.js"

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  modules: {
    config,
    login,
    messanges,
    participations,
    settings,
    menuPlan,
    preOrder,
    orders
  }
});
