<template>
  <v-dialog v-model="data.open" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ data.title }}</span>
        <v-spacer />
        <v-switch
          v-model="formData.active"
          label="Menüplan sichtbar"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pb-0">
                <v-text-field
                  v-model="formData.title"
                  label="Titel des Menüplans"
                  data-cy="title"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-0">
                <v-textarea
                  v-model="formData.description"
                  label="Beschreibung des Menüplans"
                  data-cy="description"
                  dense
                  outlined
                  rows="2"
                ></v-textarea
              ></v-col>
              <v-row>
                <div class="flex-grow-1"></div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mr-4 ma-2 mt-6">
                      <v-icon v-on="on" @click="addDayToMenuPlan()">add</v-icon>
                    </v-btn>
                  </template>
                  <span>weiteren Tag hinzufügen</span>
                </v-tooltip>
              </v-row>
              <v-col cols="12" sm="12" md="12" class="pb-0">
                <h3 class="pb-2">Tage des Menüplans</h3>
                <v-card
                  outlined
                  flat
                  class="ma-2"
                  v-bind:key="item"
                  v-for="(item, index) in formData.menuPlanItems"
                >
                  <v-row class="ml-2">
                    <v-col cols="12" sm="6" md="5" class="spacing pt-11">
                      <singleDateForm
                        v-model="item.menuDate"
                        label="Datum"
                        data-cy="schedulerDate"
                        prependIcon="event"
                      />
                    </v-col>
                    <div class="flex-grow-1"></div>
                    <v-col cols="12" sm="1" md="1" class="mr-2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon class="mr-4 ma-2 mt-6">
                            <v-icon v-on="on" @click="addDishToMenuPlan(index)"
                              >add</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>weiteres Gericht hinzufügen</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-card
                    outlined
                    flat
                    class="ma-2"
                    v-for="(dish, dishIndex) in item.menuItems"
                    v-bind:key="dishIndex"
                  >
                    <v-row class="ml-2 mr-2 mt-2">
                      <v-col cols="12" sm="6" md="8" class="pb-0">
                        <v-text-field
                          v-model="dish.title"
                          label="Bezeichnung des Gerichts"
                          data-cy="title"
                          outlined
                          dense
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-text-field
                          v-model="dish.price"
                          label="Preis in €"
                          data-cy="price"
                          type="number"
                          outlined
                          dense
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="ml-2 mr-2">
                      <v-col cols="12" sm="12" md="12" class="pb-0">
                        <v-textarea
                          v-model="dish.description"
                          label="Beschreibung des Gerichts"
                          data-cy="description"
                          dense
                          outlined
                          rows="2"
                        ></v-textarea
                      ></v-col>
                    </v-row>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="ml-2 mb-2">
                          <v-icon
                            v-on="on"
                            @click="removeDishFromMenuPlan(index, dishIndex)"
                            >delete</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Gericht entfernen</span>
                    </v-tooltip>
                  </v-card>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon class="ml-2 mb-2">
                        <v-icon v-on="on" @click="removeDayFromMenuPlan(index)"
                          >delete</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Tag entfernen</span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="secondary" outlined @click="closeform">Zurück</v-btn>
        <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
          <div v-if="data.method === 'new'">
            Speichern
          </div>
          <div v-if="data.method === 'edit'">
            Bearbeiten
          </div></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import singleDateForm from "./singleDateForm.vue";
function getDefaultData() {
  return {
    title: "",
    description: "",
    active: false,
    menuPlanItems: [
      {
        menuDate: new Date().toISOString().substr(0, 10),
        menuItems: [
          {
            title: "",
            description: "",
            price: 0,
          },
        ],
      },
    ],
  };
}

export default {
  components: { singleDateForm },
  props: ["data"],
  data: () => ({
    formData: getDefaultData(),
    rules: {
      required: (value) => !!value || "Dieses Feld wird benötigt.",
    },
    valid: false,
  }),
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate form
      if (this.$refs.form.validate()) {
        if (this.data.method === "new") {
          //create new item
          this.$store.dispatch("createMenuPlan", this.formData).then(() => {
            if (createNew) {
              //reset contact data to create new one
              this.resetData();
            } else {
              //close form
              this.closeform();
            }
          });
        } else {
          //edit item
          this.$store.dispatch("updateMenuPlan", this.formData).then(() => {
            if (createNew) {
              //reset contact data to create new one
              this.resetData();
            } else {
              //close form
              this.closeform();
            }
          });
        }
      }
    },
    resetData() {
      //reset whole form data
      this.formData = getDefaultData();
      //reset form component form only after contact create
      if (this.data.method === "new") {
        this.$refs.form.reset();
      }
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
    triggerEdit(item) {
      //set edit item from contactOverview Page trigger
      this.formData = item;
    },
    addDayToMenuPlan() {
      this.formData.menuPlanItems.push({
        menuDate: new Date().toISOString().substr(0, 10),
        menuItems: [
          {
            title: "",
            description: "",
            price: 0,
          },
        ],
      });
    },
    removeDayFromMenuPlan(index) {
      this.formData.menuPlanItems.splice(index, 1);
    },
    addDishToMenuPlan(index) {
      this.formData.menuPlanItems[index].menuItems.push({
        title: "",
        description: "",
        price: 0,
      });
    },
    removeDishFromMenuPlan(index, dishIndex) {
      this.formData.menuPlanItems[index].menuItems.splice(dishIndex, 1);
    },
  },
};
</script>
