import axios from "axios";
const actions = {
  createPreOrder(context, item) {
    let postUrl = context.getters.backendServer + "preorder";
    let config = {
      headers: {
        auth: localStorage.getItem("token"),
      },
    };
    //set user
    item.user = localStorage.getItem("userId");
    return axios
      .post(postUrl, item, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
        return e;
      });
  },
  updatePreOrder(context, item) {
    let putUrl = context.getters.backendServer + "preorder/" + item.id;
    let config = {
      headers: {
        auth: localStorage.getItem("token"),
      },
    };
    //set user
    item.user = localStorage.getItem("userId");
    return axios
      .put(putUrl, item, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
        return e;
      });
  },
  getPreOrderList(context) {
    context.commit("SET_PREORDER_LIST");
    let getUrl =
      context.getters.backendServer +
      "preorder/allList?user=" +
      localStorage.getItem("userId");
    let config = {
      headers: {
        auth: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        let responseObj = [];
        response.data.forEach((item) => {
          context.dispatch("getPreOrderOrderList", item.id).then((r) => {
            item.orders = r;
            responseObj.push(item);
          });
        });
        context.commit("SET_PREORDER_LIST", responseObj);
      })
      .catch(function(e) {
        context.commit("SET_PREORDER_LIST", e);
        context.dispatch("triggerError", e);
      });
  },
  deletePreOrder(context, value) {
    context.commit("SET_PREORDER_DELETE");
    let deleteUrl = context.getters.backendServer + "preorder/" + value.id;

    let config = {
      headers: {
        auth: localStorage.getItem("token"),
      },
    };
    axios
      .delete(deleteUrl, config)
      .then(function() {
        context.commit("SET_PREORDER_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Eintrag erfolgreich gelöscht");
        context.dispatch("getPreOrderList");
      })
      .catch(function(e) {
        context.commit("SET_PREORDER_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
