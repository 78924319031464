import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts'

import store from './store/store.js'

import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


Vue.config.productionTip = false

Vue.component('apexchart', VueApexCharts);
Vue.use(VueAxios, axios);
Vue.use(Vuex);
Vue.use(VueQuillEditor, /* { default global options } */);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
