<template>
  <v-app id="inspire">
      <navbar />
    <v-content class="main-content">
       <router-view></router-view>
    </v-content>
    <cornerModal />
    <appFooter />
  </v-app>
</template>

<script>
import navbar from "./styling/navbar.vue";
import appFooter from "./styling/footer.vue";
import cornerModal from "./styling/cornerModal.vue";
export default {
  components: {
    appFooter,
    navbar,
    cornerModal
  },
  props: {
    source: String
  },
  data: () => ({
    drawer: null
  })
};
</script>
<style lang="scss">
  @import "./src/assets/sass/core-admin.scss";
</style>