var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"timeout":2000,"left":"","color":"success"},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},[_vm._v("Ok!")])],1)],1),_c('v-card',{staticClass:"ma-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.menuPlanListGetter,"search":_vm.search,"expanded":_vm.expanded,"item-key":"id","show-expand":"","sort-by":"calories"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clickedRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Menüplan")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"search","label":"Suche...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-switch',{attrs:{"hide-details":"","label":"Bereich Menüplan aktivieren/ deaktivieren"},model:{value:(_vm.menuPlanStatus),callback:function ($$v) {_vm.menuPlanStatus=$$v},expression:"menuPlanStatus"}}),_c('div',{staticClass:"flex-grow-1"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({on:{"click":function($event){return _vm.addMenuPlan()}}},on),[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Menüplan erstellen")])])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"ma-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Telefon ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Email ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Bestellte Gerichte ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Anmerkungen ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Bestellen aufgegeben am ")])])]),_c('tbody',_vm._l((item.orders),function(order){return _c('tr',{key:order.id},[_c('td',[_vm._v(_vm._s(order.prename)+" "+_vm._s(order.surname))]),_c('td',[_vm._v(_vm._s(order.phone))]),_c('td',[_vm._v(_vm._s(order.email))]),_c('td',_vm._l((order.orderItems),function(menuItem){return _c('div',{key:menuItem},[_c('b',[_vm._v("1x "+_vm._s(menuItem.title)+" / "+_vm._s(menuItem.price)+"€")]),_c('br')])}),0),_c('td',[_vm._v(_vm._s(order.description))]),_c('td',[_vm._v(_vm._s(order.formattedCreatedAt)+"h")])])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v("delete")])]}}],null,true)},[_c('span',[_vm._v("Eintrag löschen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("edit")])]}}],null,true)},[_c('span',[_vm._v("Eintrag bearbeiten")])])]}},{key:"item.formattedVisible",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"success"}},[_vm._v("visibility")]),_vm._v(" sichtbar ")],1):_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("visibility")]),_vm._v(" verborgen ")],1)]}},{key:"item.formattedMenuPlanDays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.menuPlanItemsDates(item.menuPlanItems))+" ")]}},{key:"item.formattedOrders",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orders.length)+" ")]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("keine Daten vorhanden")])]},proxy:true}])})],1),_c('menuPlanForm',{ref:"menuPlanForm",attrs:{"data":_vm.menuPlanForm},on:{"reloadData":_vm.reloadDataFunc}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }