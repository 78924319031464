import { StoreUtil } from "../utils";

const mutations = {

    SET_MESSANGES_LIST(state, payload) {
        state.messangesList = StoreUtil.updateState(state.messangesList, payload);
    },
    SET_MESSANGES_ADD(state, payload) {
        StoreUtil.updateState(state.messangesList, payload);
    },
    SET_MESSANGES_DELETE(state, payload) {
        StoreUtil.updateState(state.messangesList, payload);
    },
    SET_MESSANGES_UPDATE(state, payload) {
        StoreUtil.updateState(state.messangesList, payload);
    }
}

export default mutations;