<template>
  <div>
    <div class="text-center">
      <v-snackbar
        v-model="snackbar.visible"
        :timeout="2000"
        left
        color="success"
      >
        {{ snackbar.text }}
        <v-btn color="primary" text @click="snackbar.visible = false"
          >Ok!</v-btn
        >
      </v-snackbar>
    </div>
    <v-card class="ma-4">
      <v-data-table
        :headers="headers"
        :items="preOrderListGetter"
        :search="search"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        sort-by="calories"
        class="elevation-1"
        @click:row="clickedRow"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Vorbestellungen</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Suche..."
              single-line
              hide-details
              dense
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-switch
              hide-details
              label="Bereich Vorbestellungen aktivieren/ deaktivieren"
              v-model="preOrderStatus"
            ></v-switch>
            <div class="flex-grow-1"></div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon>
                  <v-icon v-on="on" @click="addPreOrder()">add</v-icon>
                </v-btn>
              </template>
              <span>Vorbestellung</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="ma-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Telefon
                    </th>
                    <th class="text-left">
                      Email
                    </th>
                    <th class="text-left">
                      Bestellte Artikel
                    </th>
                    <th class="text-left">
                      Anmerkungen
                    </th>
                    <th class="text-left">
                      Bestellen aufgegeben am
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in item.orders" :key="order.id">
                    <td>{{ order.prename }} {{ order.surname }}</td>
                    <td>{{ order.phone }}</td>
                    <td>{{ order.email }}</td>
                    <td>
                      <div v-for="preOrderItem in order.orderItems" :key="preOrderItem">
                        <b> {{ preOrderItem.title }} - {{ preOrderItem.amount }} {{ preOrderItem.unit }} / {{ preOrderItem.price }}€</b
                        ><br />
                      </div>
                    </td>
                    <td>{{ order.description }}</td>
                    <td>{{ order.formattedCreatedAt }}h</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="deleteItem(item)" class="mr-2"
                >delete</v-icon
              >
            </template>
            <span>Eintrag löschen</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="editItem(item)" class="mr-2"
                >edit</v-icon
              >
            </template>
            <span>Eintrag bearbeiten</span>
          </v-tooltip>
        </template>
        <template v-slot:item.formattedVisible="{ item }">
          <div v-if="item.active">
            <v-icon class="mr-2" small color="success">visibility</v-icon>
            sichtbar
          </div>
          <div v-else>
            <v-icon class="mr-2" small>visibility</v-icon> verborgen
          </div>
        </template>
        <template v-slot:item.formattedOrders="{ item }">
          {{ item.orders.length }}
        </template>
        <template v-slot:no-data>
          <p>keine Daten vorhanden</p>
        </template>
      </v-data-table>
    </v-card>
    <preOrderForm
      ref="preOrderForm"
      :data="preOrderForm"
      @reloadData="reloadDataFunc"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import preOrderForm from "../components/preOrderForm.vue";
export default {
  components: { preOrderForm },
  data: () => ({
    search: "",
    expanded: [],
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    headers: [
      {
        text: "Bezeichnung",
        align: "left",
        sortable: false,
        value: "title",
      },
      {
        text: "Vorbestellung sichtbar",
        value: "formattedVisible",
      },
      {
        text: "Erstellt am",
        value: "formattedCreatedAt",
      },
      {
        text: "Bestellungen",
        value: "formattedOrders",
      },
      {
        text: "",
        value: "action",
        align: "right",
      },
    ],
    preOrderForm: {
      open: false,
      title: "",
      item: {},
    },
  }),
  computed: {
    ...mapGetters([
      "preOrderListGetter",
      "settingPreOrderStatus",
      "settingPreOrder",
    ]),
    preOrderStatus: {
      get: function() {
        return this.settingPreOrderStatus;
      },
      set: function(newValue) {
        this.$store
          .dispatch("setSettingPreOrderStatus", {
            value: newValue,
            setting: this.settingPreOrder,
          })
          .then((result) => {});
      },
    },
  },
  mounted() {
    this.$store.dispatch("getPreOrderList");
    this.$store.dispatch("getSettingPreOrderStatus");
    this.$store.dispatch("getSettingsList");
  },
  methods: {
    clickedRow(value) {
      let test = [];
      test.push(value);
      if (test[0] === this.expanded[0]) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    deleteItem(item) {
      this.$store.dispatch("deletePreOrder", item);
    },
    editItem(item) {
      /*his.$store.dispatch("updatePreOrder", item).then(() => {
        //reload preOrder plan data
        this.$store.dispatch("getPreOrderList");
      });*/
      this.preOrderForm.open = true;
      this.preOrderForm.title = "Vorbestellung bearbeiten";
      this.preOrderForm.method = "edit";
      this.$refs.preOrderForm.triggerEdit(Object.assign({}, item));
    },
    addPreOrder() {
      this.preOrderForm.open = true;
      this.preOrderForm.title = "Vorbestellung erstellen";
      this.preOrderForm.method = "new";
    },

    reloadDataFunc() {
      //reload data
      this.$store.dispatch("getPreOrderList");
    },
  },
};
</script>
<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
