import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  companyData: {
    name: "dorfladen.app",
    fullName: "Vereinigung der Bürger- und Dorfläden in Deutschland e.V.",
    supportMail: "support@c-dev.io"
  },
  version: "v1.4",
  backendServer: "https://api.dorfladen.app/",
  //backendServer: "http://localhost:3001/",
  errors: [],
  error: {
    visible: false,
    message: null
  },
  snackbar: {
    visible: false,
    message: null
  }
}

const configModule = {
  state,
  mutations,
  actions,
  getters
}

export default configModule;