<template>
  <div>
    <v-row class="justify-space-around">
      <v-col cols="12" sm="6" md="10">
        <v-toolbar dense class="mb-4">
          <v-app-bar-nav-icon></v-app-bar-nav-icon>

          <v-toolbar-title>Beiträge erstellen / bearbeiten</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on" @click="dialog = true">post_add</v-icon>
              </v-btn>
            </template>
            <span>Beitrag erstellen</span>
          </v-tooltip>
        </v-toolbar>
        <v-expansion-panels v-model="openPanels" multiple>
          <v-expansion-panel v-for="item in messangesListGetter" :key="item.id">
            <v-expansion-panel-header>
              <div class="headline">{{ item.heading }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider></v-divider>
              <div v-html="item.html"></div>
              <v-divider></v-divider>
              <small
                >Beitrag erstellt und versendet am
                {{ item.formattedCreatedAt }}</small
              >
              <br />
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon>
                    <v-icon small v-on="on" @click="edit(item)">edit</v-icon>
                  </v-btn>
                </template>
                <span>Beitrag bearbeiten</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon>
                    <v-icon small v-on="on" @click="deleteItem(item)"
                      >delete</v-icon
                    >
                  </v-btn>
                </template>
                <span>Beitrag löschen</span>
              </v-tooltip>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="1400px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="justify-space-around">
                <v-col cols="12" sm="6" md="11">
                  <v-text-field
                    label="Titel"
                    v-model="dialogItem.heading"
                    dense
                    outlined
                  ></v-text-field>
                  <v-textarea
                    outlined
                    dense
                    label="Vorschautext / Zusammenfassung des Beitrages"
                    v-model="dialogItem.descriptionShort"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="11">
                  <!--vue-editor v-model="dialogItem.html"></vue-editor-->
                  <quill-editor
                    v-model="dialogItem.html"
                    :options="editorOption"
                  >
                  </quill-editor>
                </v-col>
                <!--v-col cols="12" sm="6" md="11">
                  <v-file-input
                    v-model="dialogItem.file"
                    dense
                    accept=".jpg, .jpeg, .png, .pdf, .xlsx, .xls, .ppt, .pptx"
                    label="Dateien an Beitrag anhängen"
                    type="file"
                    disabled
                  ></v-file-input>
                  <input
                    type="file"
                    id="file"
                    ref="file"
                    v-on:change="handleFileUpload()"
                  />
                </v-col-->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >zurück</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveMessage"
              >speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
  components: {},
  data: () => ({
    dialog: false,
    dialogTitle: "Beitrag erstellen",
    dialogItem: {
      heading: "Beispielüberschrift",
      descriptionShort: "Kurzbeschreibung des Beitrages",
      html: "<h1>Beispieltext</h1>",
      isFile: "false",
      file: null
    },
    rulesData: [
      value =>
        !value ||
        value.size < 4000000 ||
        "Bitte lade nur Dateien kleiner 4MB hoch!"
    ],
    panels: [],
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote"],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction

          [{ size: [] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
          [("link", "image")]
        ],
        history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false
        },
        imageDrop: true,
        imageResize: true
      }
    }
  }),
  mounted() {
    this.$store.dispatch("getMessangesList").then(result => {});
  },
  computed: {
    ...mapGetters(["messangesListGetter"]),
    openPanels: function() {
      return [...Array(this.messangesListGetter.length).keys()].map(
        (k, i) => i
      );
    }
  },
  methods: {
    saveMessage() {
      let vm = this;
      vm.dialog = false;
      if (vm.dialogTitle == "Beitrag erstellen") {
        if (vm.dialogItem.file != null) {
          vm.dialogItem.isFile = "true";
          let formData = new FormData();
          formData.append("file", vm.dialogItem.file);
          vm.dialogItem.file = formData;
        }
        this.$store.dispatch("addMessanges", vm.dialogItem).then(result => {
          this.resetDialogData();
        });
      } else {
        if (vm.dialogItem.file != null) {
          vm.dialogItem.isFile = "true";
          let formData = new FormData();
          formData.append("file", vm.dialogItem.file);
          vm.dialogItem.file = formData;
        }
        vm.dialogItem.isFile = "false";
        this.$store.dispatch("updateMessanges", vm.dialogItem).then(result => {
          this.resetDialogData();
        });
      }
    },
    edit(item) {
      this.dialogTitle = "Beitrag bearbeiten";
      this.dialog = true;
      this.dialogItem = item;
    },
    deleteItem(item) {
      this.$store.dispatch("deleteMessanges", item).then(result => {});
    },
    handleFileUpload() {
      this.dialogItem.file = this.$refs.file.files[0];
    },
    resetDialogData() {
      this.dialogItem = {
        heading: "Beispielüberschrift",
        html: "<h1>Beispieltext</h1>",
        isFile: "false",
        file: null
      };
    }
  }
};
</script>
<style>
.ql-editor {
  min-height: 320px !important;
}
</style>
