var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"900px","persistent":""},model:{value:(_vm.data.open),callback:function ($$v) {_vm.$set(_vm.data, "open", $$v)},expression:"data.open"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.data.title))]),_c('v-spacer'),_c('v-switch',{attrs:{"label":"Menüplan sichtbar"},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}})],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Titel des Menüplans","data-cy":"title","outlined":"","dense":"","rules":[_vm.rules.required]},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Beschreibung des Menüplans","data-cy":"description","dense":"","outlined":"","rows":"2"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('v-row',[_c('div',{staticClass:"flex-grow-1"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-4 ma-2 mt-6",attrs:{"icon":""}},[_c('v-icon',_vm._g({on:{"click":function($event){return _vm.addDayToMenuPlan()}}},on),[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("weiteren Tag hinzufügen")])])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h3',{staticClass:"pb-2"},[_vm._v("Tage des Menüplans")]),_vm._l((_vm.formData.menuPlanItems),function(item,index){return _c('v-card',{key:item,staticClass:"ma-2",attrs:{"outlined":"","flat":""}},[_c('v-row',{staticClass:"ml-2"},[_c('v-col',{staticClass:"spacing pt-11",attrs:{"cols":"12","sm":"6","md":"5"}},[_c('singleDateForm',{attrs:{"label":"Datum","data-cy":"schedulerDate","prependIcon":"event"},model:{value:(item.menuDate),callback:function ($$v) {_vm.$set(item, "menuDate", $$v)},expression:"item.menuDate"}})],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-col',{staticClass:"mr-2",attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-4 ma-2 mt-6",attrs:{"icon":""}},[_c('v-icon',_vm._g({on:{"click":function($event){return _vm.addDishToMenuPlan(index)}}},on),[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v("weiteres Gericht hinzufügen")])])],1)],1),_vm._l((item.menuItems),function(dish,dishIndex){return _c('v-card',{key:dishIndex,staticClass:"ma-2",attrs:{"outlined":"","flat":""}},[_c('v-row',{staticClass:"ml-2 mr-2 mt-2"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-text-field',{attrs:{"label":"Bezeichnung des Gerichts","data-cy":"title","outlined":"","dense":"","rules":[_vm.rules.required]},model:{value:(dish.title),callback:function ($$v) {_vm.$set(dish, "title", $$v)},expression:"dish.title"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Preis in €","data-cy":"price","type":"number","outlined":"","dense":"","rules":[_vm.rules.required]},model:{value:(dish.price),callback:function ($$v) {_vm.$set(dish, "price", $$v)},expression:"dish.price"}})],1)],1),_c('v-row',{staticClass:"ml-2 mr-2"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Beschreibung des Gerichts","data-cy":"description","dense":"","outlined":"","rows":"2"},model:{value:(dish.description),callback:function ($$v) {_vm.$set(dish, "description", $$v)},expression:"dish.description"}})],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"icon":""}},[_c('v-icon',_vm._g({on:{"click":function($event){return _vm.removeDishFromMenuPlan(index, dishIndex)}}},on),[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Gericht entfernen")])])],1)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"icon":""}},[_c('v-icon',_vm._g({on:{"click":function($event){return _vm.removeDayFromMenuPlan(index)}}},on),[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Tag entfernen")])])],2)})],2)],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":_vm.closeform}},[_vm._v("Zurück")]),_c('v-btn',{attrs:{"color":"primary","data-cy":"saveEvent"},on:{"click":function($event){return _vm.saveForm(false)}}},[(_vm.data.method === 'new')?_c('div',[_vm._v(" Speichern ")]):_vm._e(),(_vm.data.method === 'edit')?_c('div',[_vm._v(" Bearbeiten ")]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }