import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { StoreUtil } from "../utils";

const state = {
  preOrderList: StoreUtil.state()
};

const preOrderModule = {
  state,
  mutations,
  actions,
  getters
};

export default preOrderModule;
