<template>
  <v-dialog v-model="data.open" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ data.title }}</span>
        <v-spacer />
        <v-switch
          v-model="formData.active"
          label="Vorbestellung sichtbar"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pb-0">
                <v-text-field
                  v-model="formData.title"
                  label="Titel des Vorbestellungs-Aktion"
                  data-cy="title"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-0">
                <v-textarea
                  v-model="formData.description"
                  label="Beschreibung der Vorbestellung"
                  data-cy="description"
                  dense
                  outlined
                  rows="2"
                ></v-textarea
              ></v-col>
              <v-col cols="12" sm="12" md="12" class="pb-8">
                <h4 class="pb-2">Erweiterte Beschreibung der Vorbestellung</h4>
                <quill-editor
                  v-model="formData.descriptionLong"
                  :options="editorOption"
                >
                </quill-editor>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-0 pt-2">
                <h3 class="pb-0">Artikel der Vorbestellung</h3>

                <v-row class="ml-2">
                  <div class="flex-grow-1"></div>
                  <v-col cols="12" sm="1" md="1" class="mr-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mr-4 ma-2 mt-6">
                          <v-icon v-on="on" @click="addDishToPreOrder()"
                            >add</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>weiteres Artikel hinzufügen</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-card
                  outlined
                  flat
                  class="ma-2"
                  v-bind:key="orderIndex"
                  v-for="(order, orderIndex) in formData.preOrderItems"
                >
                  <v-row class="ml-2 mr-2 mt-2">
                    <v-col cols="12" sm="6" md="8" class="pb-0">
                      <v-text-field
                        v-model="order.title"
                        label="Bezeichnung des Artikels"
                        data-cy="title"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2" class="pb-0">
                      <v-text-field
                        v-model="order.unit"
                        label="Einheit"
                        data-cy="unit"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2" class="pb-0">
                      <v-text-field
                        v-model="order.price"
                        label="Preis in €"
                        data-cy="price"
                        type="number"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ml-2 mr-2">
                    <v-col cols="12" sm="12" md="12" class="pb-0">
                      <v-textarea
                        v-model="order.description"
                        label="Beschreibung des Artikels"
                        data-cy="description"
                        dense
                        outlined
                        rows="2"
                      ></v-textarea
                    ></v-col>
                  </v-row>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon class="ml-2 mb-2">
                        <v-icon
                          v-on="on"
                          @click="removeDishFromPreOrder(orderIndex)"
                          >delete</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Artikel entfernen</span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="secondary" outlined @click="closeform">Zurück</v-btn>
        <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
          <div v-if="data.method === 'new'">
            Speichern
          </div>
          <div v-if="data.method === 'edit'">
            Bearbeiten
          </div></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQuillEditor, { Quill } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
function getDefaultData() {
  return {
    title: "",
    description: "",
    descriptionLong: "",
    active: false,
    preOrderItems: [
      {
        title: "",
        description: "",
        price: 0,
      },
    ],
  };
}

export default {
  props: ["data"],
  data: () => ({
    formData: getDefaultData(),
    rules: {
      required: (value) => !!value || "Dieses Feld wird benötigt.",
    },
    valid: false,
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote"],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction

          [{ size: [] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
          [("link", "image")],
        ],
        history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false,
        },
        imageDrop: true,
        imageResize: true,
      },
    },
  }),
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate form
      if (this.$refs.form.validate()) {
        if (this.data.method === "new") {
          //create new item
          this.$store.dispatch("createPreOrder", this.formData).then(() => {
            if (createNew) {
              //reset contact data to create new one
              this.resetData();
            } else {
              //close form
              this.closeform();
            }
          });
        } else {
          //edit item
          this.$store.dispatch("updatePreOrder", this.formData).then(() => {
            if (createNew) {
              //reset contact data to create new one
              this.resetData();
            } else {
              //close form
              this.closeform();
            }
          });
        }
      }
    },
    resetData() {
      //reset whole form data
      this.formData = getDefaultData();
      //reset form component form only after contact create
      if (this.data.method === "new") {
        this.$refs.form.reset();
      }
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
    triggerEdit(item) {
      //set edit item from contactOverview Page trigger
      this.formData = item;
    },
    addDishToPreOrder() {
      this.formData.preOrderItems.push({
        title: "",
        description: "",
        price: 0,
      });
    },
    removeDishFromPreOrder(orderIndex) {
      this.formData.preOrderItems.splice(orderIndex, 1);
    },
  },
};
</script>
