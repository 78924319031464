import axios from "axios";
const actions = {
  getParticipationsList(context) {
    context.commit("SET_PARTICIPATIONS_LIST");
    let getUrl = context.getters.backendServer + "share";
    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_PARTICIPATIONS_LIST", response.data);
      })
      .catch(function(e) {
        context.commit("SET_PARTICIPATIONS_LIST", e);
        context.dispatch("triggerError", e);
      });
  },
  deleteParticipations(context, value) {
    context.commit("SET_PARTICIPATIONS_DELETE");
    let deleteUrl = context.getters.backendServer + "share/" + value.id;

    let config = {
      headers: {
        auth: localStorage.getItem("token")
      }
    };
    axios
      .delete(deleteUrl, config)
      .then(function() {
        context.commit("SET_PARTICIPATIONS_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Eintrag erfolgreich gelöscht");
        context.dispatch("getParticipationsList");
      })
      .catch(function(e) {
        context.commit("SET_PARTICIPATIONS_DELETE", e);
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
