import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    token: null,
    static_token: null,
    loggedIn: false,
    loading: false
}

const loginModule = {
    state,
    mutations,
    actions,
    getters
}

export default loginModule;